import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const RoutesConfig = [
  {
    exact: true,
    path: '/docs',
    component: () => <Redirect to="/docs/welcome" />
  },
  {
    exact: true,
    path: '/docs/welcome',
    component: lazy(() => import('src/docs/WelcomeView'))
  },
  {
    exact: true,
    path: '/docs/getting-started',
    component: lazy(() => import('src/docs/GettingStartedView'))
  },
  {
    exact: true,
    path: '/docs/support',
    component: lazy(() => import('src/docs/SupportView'))
  },
  {
    exact: true,
    path: '/docs/changelog',
    component: lazy(() => import('src/docs/ChangelogView'))
  },
  {
    component: () => <Redirect to="/404" />
  }
];

export default RoutesConfig;
