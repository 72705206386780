import produce from 'immer';
import * as Actions from 'src/actions/types';
import * as AccountActions from 'src/actions/accountActions';
import * as Entities from 'src/config/Entities';

const initialState = {
  navigation: {
    tab: 'details'
  },
  tenant: null,
  tenants: null,
  pageinfo: null,
  fetching: false,
  mode: Entities.EntityMode.view
};

const mytenantReducer = (state = initialState, action) => {
  switch (action.type) {
    // NAVIGATION
    case Actions.ME_TENANT_NAVIGATION_SET: {
      const { navigation } = action.payload;
      return produce(state, draft => {
        const newNav = {
          ...state.navigation,
          ...navigation
        };
        draft.navigation = newNav;
      });
    }

    // LIST
    case Actions.ME_TENANT_LIST_REQUEST: {
      return produce(state, draft => {
        draft.fetching = true;
      });
    }
    case Actions.ME_TENANT_LIST_RECEIVE: {
      const { entities, pageinfo } = action.payload;
      return produce(state, draft => {
        draft.pageinfo = pageinfo;
        draft.tenants = entities;
        draft.fetching = false;
      });
    }

    // ENTITY
    case Actions.ME_TENANT_MODE: {
      const { mode } = action.payload;
      return produce(state, draft => {
        draft.mode = mode;
      });
    }
    case Actions.ME_TENANT_REQUEST: {
      return produce(state, draft => {
        draft.fetching = true;
      });
    }
    case Actions.ME_TENANT_RECEIVE:
    case Actions.ME_TENANT_UPDATE: {
      const { entity } = action.payload;
      return produce(state, draft => {
        draft.tenant = entity;
        draft.fetching = false;
      });
    }
    case Actions.ME_TENANT_FINISHED: {
      return produce(state, draft => {
        draft.fetching = false;
      });
    }

    //------------------------------
    // From Account
    //------------------------------

    case AccountActions.LOGOUT:
    case AccountActions.LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.tenant = null;
        draft.tenants = null;
        draft.pageinfo = null;
        draft.fetching = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default mytenantReducer;
