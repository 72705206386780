import { Home as HomeIcon } from 'react-feather';
import * as Enum from 'src/config/Enumerations';

export const getNavigationTree = t => {
  const navConfig = [
    //#region MY
    {
      subheader: t('navigation2121:My.Group'),
      access: Enum.AccessLevel.Member,
      items: [
        {
          title: t('navigation2121:My.Home'),
          access: Enum.AccessLevel.Member,
          icon: HomeIcon,
          href: '/home'
        }
      ]
    }
    //#endregion
  ];

  return navConfig;
};
