import produce from 'immer';
import objFromArray from 'src/utils/objFromArray';
import * as Actions from 'src/actions/types';
import * as Settings from 'src/config/Settings';
import * as Entities from 'src/config/Entities';

const initialState = {
  labels: [],
  labelSelected: null,
  navigation: {
    page: 0,
    pagelength: Settings.Data.pagelength,
    sort: Settings.Data.sort,
    search: '',
    tab: 'details'
  },
  query: {
    params: null,
    result: null
  },
  entities: {
    byId: {},
    allIds: []
  },
  entityId: null,
  fetching: false,
  mode: Entities.EntityMode.view
};

const travelsReducer = (state = initialState, action) => {
  switch (action.type) {
    // LABELS
    case Actions.TRAVEL_LABELS_GET: {
      const { labels } = action.payload;
      return produce(state, draft => {
        draft.labels = labels;
      });
    }
    case Actions.TRAVEL_LABEL_SELECT: {
      const { label } = action.payload;
      return produce(state, draft => {
        draft.labelSelected = label;
      });
    }

    // NAVIGATION
    case Actions.TRAVEL_NAVIGATION_SET: {
      const { navigation } = action.payload;
      return produce(state, draft => {
        const newNav = {
          ...state.navigation,
          ...navigation
        };
        draft.navigation = newNav;
      });
    }

    // LIST
    case Actions.TRAVEL_LIST_REQUEST:
    case Actions.TRAVEL_LIST_REQUEST_MORE: {
      const { params } = action.payload;
      return produce(state, draft => {
        draft.query.params = params;
        draft.fetching = true;
      });
    }
    case Actions.TRAVEL_LIST_RECEIVE: {
      const { entities, pageinfo } = action.payload;
      return produce(state, draft => {
        draft.query.result = pageinfo;
        draft.entities.byId = objFromArray(entities, '_id');
        draft.entities.allIds = Object.keys(draft.entities.byId);
        draft.fetching = false;
      });
    }
    case Actions.TRAVEL_LIST_APPEND: {
      const { entities, pageinfo } = action.payload;
      return produce(state, draft => {
        const actualSelected = state.query ? state.query.selected : 0;
        const appendSelected = pageinfo ? pageinfo.selected : 0;
        const newPageInfo = {
          ...pageinfo,
          selected: actualSelected + appendSelected
        };
        draft.query.result = newPageInfo;
        const newById = {
          ...state.entities.byId,
          ...objFromArray(entities, '_id')
        };
        draft.entities.byId = { ...newById };
        draft.entities.allIds = Object.keys(draft.entities.byId);
        draft.fetching = false;
      });
    }

    // ENTITY
    case Actions.TRAVEL_MODE: {
      const { mode } = action.payload;
      return produce(state, draft => {
        draft.mode = mode;
      });
    }
    case Actions.TRAVEL_SELECT: {
      const { entityId } = action.payload;
      return produce(state, draft => {
        draft.entityId = entityId;
      });
    }
    case Actions.TRAVEL_REQUEST: {
      return produce(state, draft => {
        draft.fetching = true;
      });
    }
    case Actions.TRAVEL_RECEIVE:
    case Actions.TRAVEL_ADD:
    case Actions.TRAVEL_UPDATE: {
      const { entity } = action.payload;
      return produce(state, draft => {
        draft.entityId = entity._id;
        draft.entities.byId[entity._id] = entity;
        draft.fetching = false;

        if (!draft.entities.allIds.includes(entity._id)) {
          draft.entities.allIds.push(entity._id);
        }
      });
    }
    case Actions.TRAVEL_DELETE: {
      const { entityId } = action.payload;
      return produce(state, draft => {
        let entities = { ...state.entities.byId };
        delete entities[entityId];
        draft.entities.byId = { ...entities };
        draft.entityId = null;
        draft.fetching = false;

        if (draft.entities.allIds.includes(entityId)) {
          let keys = [...draft.entities.allIds];
          const index = keys.indexOf(entityId);
          if (index > -1) {
            keys.splice(index, 1);
          }
          draft.entities.allIds = [...keys];
        }
      });
    }
    case Actions.TRAVEL_FINISHED: {
      return produce(state, draft => {
        draft.fetching = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default travelsReducer;
