import jwtDecode from 'jwt-decode';
import * as ApiService from 'src/utils/axiosService';
import { ApplicationClient } from 'src/config/Application';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    ApiService.toAPI.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) onLogout();
        }
        return Promise.reject(error);
      }
    );
  };

  handleAccessToken() {
    //console.log('=== HANDLE AUTH ===');
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      console.log('no access token');
      return;
    }
    if (this.isValidToken(accessToken)) {
      console.log('token is valid');
      this.setSession(accessToken);
    } else {
      console.log('token is NOT valid');
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      //console.log('=== LOGIN ===');
      const appid = ApplicationClient.appKey;
      ApiService.toAuth
        .post('/login', { email, password, appid })
        .then(response => {
          //console.log('Response');
          //console.log(response);
          if (response.data.success) {
            this.setSession(response.data.token);
            resolve(response.data.entity);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      // console.log('=== LOGIN WITH TOKEN ===');
      ApiService.toAuth
        .get('/me')
        .then(response => {
          // console.log('Response');
          // console.log(response);
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          console.log('Error');
          console.log(error);
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      ApiService.toAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      ApiService.toAuth.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete ApiService.toAPI.defaults.headers.common.Authorization;
      delete ApiService.toAuth.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();
export default authService;
