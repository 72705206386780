import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { silentLogin, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      console.log('=== INIT AUTH ===');

      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });
      authService.handleAccessToken();
      if (authService.isAuthenticated()) {
        await dispatch(silentLogin());
      }
      setLoading(false);
    };
    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }
  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
