import * as Actions from 'src/actions/types';

export const openSidebar = () => {
  return {
    type: Actions.SIDEBAR_OPEN
  };
};

export const closeSidebar = () => {
  return {
    type: Actions.SIDEBAR_CLOSE
  };
};

export const clearErrors = () => {
  return {
    type: Actions.ERRORS_CLEAR
  };
};

export const clearAsset = () => {
  return {
    type: Actions.APP_ASSET_CLEAR
  };
};

export const requestAsset = params => {
  return async dispatch => {
    dispatch({
      type: Actions.APP_ASSET_REQUEST,
      payload: { params }
    });
  };
};

export const receiveAsset = asset => {
  return async dispatch => {
    dispatch({
      type: Actions.APP_ASSET_RECEIVE,
      payload: { asset }
    });
  };
};
