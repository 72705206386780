import produce from 'immer';
import * as Actions from 'src/actions/types';
import * as AccountActions from 'src/actions/accountActions';
import * as Entities from 'src/config/Entities';

const initialState = {
  navigation: {
    tab: 'details'
  },
  status: null,
  fetching: false,
  mode: Entities.EntityMode.view
};

const mystatusReducer = (state = initialState, action) => {
  switch (action.type) {
    // NAVIGATION
    case Actions.ME_STATUS_NAVIGATION_SET: {
      const { navigation } = action.payload;
      return produce(state, draft => {
        const newNav = {
          ...state.navigation,
          ...navigation
        };
        draft.navigation = newNav;
      });
    }

    // ENTITY
    case Actions.ME_STATUS_MODE: {
      const { mode } = action.payload;
      return produce(state, draft => {
        draft.mode = mode;
      });
    }
    case Actions.ME_STATUS_REQUEST: {
      return produce(state, draft => {
        draft.fetching = true;
      });
    }
    case Actions.ME_STATUS_RECEIVE:
    case Actions.ME_STATUS_UPDATE: {
      const { entity } = action.payload;
      return produce(state, draft => {
        draft.status = entity;
        draft.fetching = false;
      });
    }
    case Actions.ME_STATUS_FINISHED: {
      return produce(state, draft => {
        draft.fetching = false;
      });
    }

    //------------------------------
    // From Account
    //------------------------------

    case AccountActions.LOGIN_REQUEST:
    case AccountActions.LOGOUT: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.status = null;
        draft.fetching = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default mystatusReducer;
