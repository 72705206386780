import { User as ProfileIcon } from 'react-feather';
import { Command as OrganizationIcon } from 'react-feather';
import * as Enum from 'src/config/Enumerations';

export const getNavigationTree = t => {
  const navConfig = [
    //#region SETTINGS
    {
      subheader: t('navigation2121:Settings.Group'),
      access: Enum.AccessLevel.Member,
      items: [
        {
          title: t('navigation2121:Settings.MyProfile'),
          access: Enum.AccessLevel.Member,
          icon: ProfileIcon,
          href: '/app/account/profile/view'
        },
        {
          title: t('navigation2121:Settings.MyOrganization'),
          access: Enum.AccessLevel.User,
          icon: OrganizationIcon,
          href: '/app/account/organization/view'
        }
      ]
    }
    //#endregion
  ];

  return navConfig;
};
