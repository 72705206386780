import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const RoutesConfig = [
  //#region MY

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/my',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },
  {
    exact: true,
    path: '/app/appointments',
    component: lazy(() => import('src/views/calendar/MyAppointments'))
  },
  {
    exact: true,
    path: '/app/taskcalendar',
    component: lazy(() => import('src/views/calendar/MyCourseCalendar'))
  },
  //#endregion

  //#region -- MESSAGES
  {
    exact: true,
    path: [
      '/app/messages/label/:customLabel/:mailId?',
      '/app/messages/:systemLabel/:mailId?'
    ],
    component: lazy(() => import('src/views/messages/Demo'))
  },
  {
    exact: true,
    path: '/app/messages',
    component: () => <Redirect to="/app/messages/all" />
  }

  //#endregion

  //#endregion
];

export default RoutesConfig;
