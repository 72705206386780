import { lazy } from 'react';

const RoutesConfig = [
  //#region -- BUSINESSPARTNERS
  {
    exact: true,
    path: [
      '/app/businesspartners/label/:customLabel',
      '/app/businesspartners/:systemLabel',
      '/app/businesspartners'
    ],
    component: lazy(() => import('src/views/businesspartners'))
  },
  {
    exact: true,
    path: '/app/businesspartner/edit/:entityId',
    component: lazy(() => import('src/views/businesspartners/EditView'))
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-contact/:entityId',
    component: lazy(() => import('src/views/businesspartners/EditView/Contact'))
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-customer/:entityId',
    component: lazy(() =>
      import('src/views/businesspartners/EditView/Customer')
    )
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-supplier/:entityId',
    component: lazy(() =>
      import('src/views/businesspartners/EditView/Supplier')
    )
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-operator/:entityId',
    component: lazy(() =>
      import('src/views/businesspartners/EditView/Operator')
    )
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-address/:entityId',
    component: lazy(() => import('src/views/businesspartners/EditView/Address'))
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-payment/:entityId',
    component: lazy(() => import('src/views/businesspartners/EditView/Payment'))
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-numbering/:entityId',
    component: lazy(() =>
      import('src/views/businesspartners/EditView/Numbering')
    )
  },
  {
    exact: true,
    path: '/app/businesspartner/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/businesspartners/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/businesspartner/view/:entityId',
    component: lazy(() => import('src/views/businesspartners/DetailsView'))
  },
  {
    exact: true,
    path: '/app/businesspartner/create',
    component: lazy(() => import('src/views/businesspartners/CreateView'))
  },
  //#endregion

  //#region -- BUSINESSPARTNER-GROUPS
  {
    exact: true,
    path: [
      '/app/businesspartnergroups/label/:customLabel',
      '/app/businesspartnergroups/:systemLabel',
      '/app/businesspartnergroups'
    ],
    component: lazy(() => import('src/views/businesspartnergroups'))
  },
  {
    exact: true,
    path: '/app/businesspartnergroup/edit/:entityId',
    component: lazy(() => import('src/views/businesspartnergroups/EditView'))
  },
  {
    exact: true,
    path: '/app/businesspartnergroup/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/businesspartnergroups/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/businesspartnergroup/view/:entityId',
    component: lazy(() => import('src/views/businesspartnergroups/DetailsView'))
  },
  {
    exact: true,
    path: '/app/businesspartnergroup/create',
    component: lazy(() => import('src/views/businesspartnergroups/CreateView'))
  },
  //#endregion

  //#region -- BUSINESSPARTNER-CATS
  {
    exact: true,
    path: [
      '/app/businesspartnercats/label/:customLabel',
      '/app/businesspartnercats/:systemLabel',
      '/app/businesspartnercats'
    ],
    component: lazy(() => import('src/views/businesspartnercats'))
  },
  {
    exact: true,
    path: '/app/businesspartnercat/edit/:entityId',
    component: lazy(() => import('src/views/businesspartnercats/EditView'))
  },
  {
    exact: true,
    path: '/app/businesspartnercat/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/businesspartnercats/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/businesspartnercat/view/:entityId',
    component: lazy(() => import('src/views/businesspartnercats/DetailsView'))
  },
  {
    exact: true,
    path: '/app/businesspartnercat/create',
    component: lazy(() => import('src/views/businesspartnercats/CreateView'))
  },
  //#endregion

  //#region -- CONTACTS
  {
    exact: true,
    path: [
      '/app/contacts/label/:customLabel',
      '/app/contacts/:systemLabel',
      '/app/contacts'
    ],
    component: lazy(() => import('src/views/contacts'))
  },
  {
    exact: true,
    path: '/app/contact/edit/:entityId',
    component: lazy(() => import('src/views/contacts/EditView'))
  },
  {
    exact: true,
    path: '/app/contact/edit-address/:entityId',
    component: lazy(() => import('src/views/contacts/EditView/Address'))
  },
  {
    exact: true,
    path: '/app/contact/edit-additional/:entityId',
    component: lazy(() => import('src/views/contacts/EditView/Additional'))
  },
  {
    exact: true,
    path: '/app/contact/edit-bio/:entityId',
    component: lazy(() => import('src/views/contacts/EditView/Bio'))
  },
  {
    exact: true,
    path: '/app/contact/edit-description/:entityId',
    component: lazy(() => import('src/views/contacts/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/contact/view/:entityId',
    component: lazy(() => import('src/views/contacts/DetailsView'))
  },
  {
    exact: true,
    path: '/app/contact/create',
    component: lazy(() => import('src/views/contacts/CreateView'))
  },
  //#endregion

  //#region PRODUCT

  //#region -- PRODUCTS
  {
    exact: true,
    path: [
      '/app/products/label/:customLabel',
      '/app/products/:systemLabel',
      '/app/products'
    ],
    component: lazy(() => import('src/views/products'))
  },
  {
    exact: true,
    path: '/app/product/edit/:entityId',
    component: lazy(() => import('src/views/products/EditView'))
  },
  {
    exact: true,
    path: '/app/product/edit-sales/:entityId',
    component: lazy(() => import('src/views/products/EditView/Sales'))
  },
  {
    exact: true,
    path: '/app/product/edit-purchase/:entityId',
    component: lazy(() => import('src/views/products/EditView/Purchase'))
  },
  {
    exact: true,
    path: '/app/product/edit-description/:entityId',
    component: lazy(() => import('src/views/products/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/product/view/:entityId',
    component: lazy(() => import('src/views/products/DetailsView'))
  },
  {
    exact: true,
    path: '/app/product/create',
    component: lazy(() => import('src/views/products/CreateView'))
  },
  //#endregion

  //#region -- PRODUCT-GROUPS
  {
    exact: true,
    path: [
      '/app/productgroups/label/:customLabel',
      '/app/productgroups/:systemLabel',
      '/app/productgroups'
    ],
    component: lazy(() => import('src/views/productgroups'))
  },
  {
    exact: true,
    path: '/app/productgroup/edit/:entityId',
    component: lazy(() => import('src/views/productgroups/EditView'))
  },
  {
    exact: true,
    path: '/app/productgroup/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/productgroups/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/productgroup/view/:entityId',
    component: lazy(() => import('src/views/productgroups/DetailsView'))
  },
  {
    exact: true,
    path: '/app/productgroup/create',
    component: lazy(() => import('src/views/productgroups/CreateView'))
  },
  //#endregion

  //#region -- PRICINGS
  {
    exact: true,
    path: [
      '/app/pricings/label/:customLabel',
      '/app/pricings/:systemLabel',
      '/app/pricings'
    ],
    component: lazy(() => import('src/views/pricings'))
  },
  {
    exact: true,
    path: '/app/pricing/edit/:entityId',
    component: lazy(() => import('src/views/pricings/EditView'))
  },
  {
    exact: true,
    path: '/app/pricing/edit-description/:entityId',
    component: lazy(() => import('src/views/pricings/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/pricing/view/:entityId',
    component: lazy(() => import('src/views/pricings/DetailsView'))
  },
  {
    exact: true,
    path: '/app/pricing/create',
    component: lazy(() => import('src/views/pricings/CreateView'))
  }
  //#endregion

  //#endregion
];

export default RoutesConfig;
