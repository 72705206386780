const objFromArray = (arr, key = '_id') => {
  if (arr && arr.length > 0) {
    return arr.reduce((accumulator, current) => {
      accumulator[current[key]] = current;
      return accumulator;
    }, {});
  }
  return {};
};

export default objFromArray;
