import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
//import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/pages/HomeView';
import AuthGuard from 'src/components/Auth/AuthGuard';
import PersonalRoutes from './PersonalRoutes';
import CampusRoutes from './CampusRoutes';
import ContentRoutes from './ContentRoutes';
import CrmRoutes from './CrmRoutes';
import FinalRoutes from './FinalRoutes';
import DocRoutes from './DocRoutes';

const RoutesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/pages/Error404View'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      ...PersonalRoutes,
      ...CampusRoutes,
      ...ContentRoutes,
      ...CrmRoutes,
      ...FinalRoutes
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [...DocRoutes]
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default RoutesConfig;
