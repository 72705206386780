import * as Enum from 'src/config/Enumerations';
import * as PersonalNavs from './PersonalNavs';
import * as ContentNavs from './ContentNavs';
import * as CampusNavs from './CampusNavs';
// import * as ProjectNavs from './ProjectNavs';
import * as SettingsNavs from './SettingsNavs';

const userHasAccess = (accessLevel, userType) => {
  let hasAccess = false;

  if (userType === Enum.UserType.BackendAdmin) {
    hasAccess = true;
  }
  if (userType === Enum.UserType.BackendUser) {
    hasAccess = true;
  }
  if (userType === Enum.UserType.BackendMember) {
    if (accessLevel !== Enum.AccessLevel.User) {
      hasAccess = true;
    }
  }
  return hasAccess;
};

const reduceChildRoutes = ({ acc, item, userType }) => {
  if (userHasAccess(item.access, userType)) {
    if (item.items) {
      const userItems = getUserNavItems(item.items, userType);
      const userItem = { ...item, items: userItems };
      acc.push(userItem);
    } else {
      const userItem = { ...item };
      acc.push(userItem);
    }
  }
  return acc;
};

const getUserNavItems = (navItems, userType) => {
  let userNavItems = [];

  userNavItems = navItems.reduce(
    (acc, item) => reduceChildRoutes({ acc, item, userType }),
    []
  );
  return userNavItems;
};

export const getNavigationTree = (t, appSelected, userType) => {
  const basicNavs = PersonalNavs.getNavigationTree(t);
  let navTree = [...basicNavs];

  // Add Content menu items
  // TODO: check license
  if (appSelected === Enum.ApplicationModule.Content) {
    const addNavs = ContentNavs.getNavigationTree(t);
    navTree = [...navTree, ...addNavs];
  }

  // Add Project menu items
  // TODO: check license
  // if (appSelected === Enum.ApplicationModule.Project) {
  //   const addNavs = ProjectNavs.getNavigationTree(t);
  //   navTree = [...navTree, ...addNavs];
  // }

  // Add Campus menu items
  // TODO: check license
  if (appSelected === Enum.ApplicationModule.Campus) {
    const addNavs = CampusNavs.getNavigationTree(t);
    navTree = [...navTree, ...addNavs];
  }

  // Add Settings menu items
  const settingsNavs = SettingsNavs.getNavigationTree(t);
  navTree = [...navTree, ...settingsNavs];
  const userNavTree = getUserNavItems(navTree, userType);

  return userNavTree;
};
