import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Collapse, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { clearErrors } from 'src/actions/appActions';

const useStyles = makeStyles(() => ({
  root: {}
}));

function AlertBar({ className, ...rest }) {
  // PARAMS & DEFINITIONS
  const classes = useStyles();
  const dispatch = useDispatch();

  // STATE & DATA
  const { errors } = useSelector(state => state.app);

  const errorIds = Object.keys(errors);
  const first = errorIds && errorIds.length > 0 ? errorIds[0] : null;
  const message = errors && first ? errors[first] : '';
  const showMessage = message ? true : false;

  // HELPER

  // EVENT HANDLER
  const handleAlertClose = () => {
    dispatch(clearErrors());
  };

  // DATA LOAD

  // RENDER
  return (
    <div className={clsx(classes.root, className)}>
      <Collapse in={showMessage}>
        <Alert severity="error" onClose={handleAlertClose} {...rest}>
          {message}
        </Alert>
      </Collapse>
    </div>
  );
}

export default AlertBar;
