/* eslint-disable react/no-array-index-key */
import React, { Suspense, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import RoutesConfig from 'src/config/routes';

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const routeTree = renderRoutes(RoutesConfig);
  return routeTree;
}

export default Routes;
