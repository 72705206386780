/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Divider } from '@material-ui/core';
import { Link, List, ListSubheader } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import NoImage from 'src/assets/images/logo-app-img-58.png';
import * as NavConfig from 'src/config/navigation';
import * as Enum from 'src/config/Enumerations';
import * as InputHelpers from 'src/utils/inputHelper';
import * as EnumLabel from 'src/data/localize/enumLabel';
import NavItem from './NavItem';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        exact={item.exact}
      />
    );
  }

  return acc;
}

function NavList(props) {
  // PARAMS & DEFINITIONS
  const location = useLocation();
  const { t } = useTranslation([
    'translation2121',
    'navigation2121',
    'enums2121'
  ]);

  // STATE & DATA
  const { organization } = useSelector(state => state.myorganization);
  const { delegate } = useSelector(state => state.mydelegate);

  const userType = InputHelpers.getUserTypeFromDelegate(delegate);
  const appSelected = delegate ? delegate.appselected : '';
  const siteSelected = delegate ? delegate.siteselected : null;
  const labelOrg = organization ? organization.name : '--';
  const labelApp = appSelected
    ? EnumLabel.getApplicationModuleLabel(t, appSelected)
    : '--';
  const labelSite =
    appSelected === Enum.ApplicationModule.Content && siteSelected
      ? siteSelected.name
      : '';
  const imageLink =
    organization && organization.logo ? organization.logo.secureUrl : NoImage;

  const navConfig = NavConfig.getNavigationTree(t, appSelected, userType);

  // HELPER

  // EVENT HANDLER

  // DATA LOAD

  // RENDER
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box mt={1} display="flex" justifyContent="center">
            <RouterLink to="/app/account/organization/view">
              <img
                style={{ maxHeight: 64, maxWidth: 200 }}
                alt={labelOrg}
                src={imageLink}
              />
            </RouterLink>
          </Box>
          <Box mt={1} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account/organization/view"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {labelOrg}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {labelApp}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {labelSite}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
}

export default NavList;
