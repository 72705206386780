import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Box, Container, Grid, Link } from '@material-ui/core';
import { Typography, makeStyles } from '@material-ui/core';
import * as Enum from 'src/config/Enumerations';
import * as EnumLabel from 'src/data/localize/enumLabel';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 60,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

function Hero({ className, ...rest }) {
  // PARAMS & DEFINITIONS
  const classes = useStyles();
  const { t, i18n } = useTranslation([
    'translation2121',
    'pages2121',
    'enums2121'
  ]);
  const actualLanguage = i18n.language;
  const isDE = actualLanguage === 'de';

  // STATE & DATA
  const { user } = useSelector(state => state.me);
  const { organization } = useSelector(state => state.myorganization);
  const { delegate } = useSelector(state => state.mydelegate);

  const userName = user ? user.name : '';
  const appSelected = delegate ? delegate.appselected : '';
  const siteSelected = delegate ? delegate.siteselected : null;
  const hasSite =
    appSelected === Enum.ApplicationModule.Content && siteSelected;

  // Lables
  const labelAppVal = appSelected
    ? EnumLabel.getApplicationModuleLabel(t, appSelected)
    : '--';
  const labelOrgVal = organization ? organization.name : '--';
  const labelSiteVal = hasSite ? siteSelected.name : '';

  //const labelHeroTitle = t('pages2121:LandingPage.Hero.Title');
  const labelHeroTitle = 'appsolut 365';
  let labelHeroSubTitle = t('pages2121:LandingPage.Hero.SubTitle');
  let labelHeroContent = t('pages2121:LandingPage.Hero.Content');
  const labelHeroWelcome = t('pages2121:LandingPage.Hero.Welcome');
  const labelHeroContext = t('pages2121:LandingPage.Hero.Context');
  const labelOrganization = t('translation2121:Label.Organization');
  const labelApplication = t('translation2121:Label.Application');
  const labelSite = t('translation2121:Label.Site');
  if (appSelected === Enum.ApplicationModule.Content) {
    labelHeroSubTitle = isDE
      ? 'CMS - Multi Channel CMS'
      : 'CMS - Multi Channel CMS';
    labelHeroContent = isDE
      ? 'Portal zur operativen Steuerung Ihrer Content Management Aktivitäten. Organisieren Sie Sites, Blogs, Dokumentation und Leitfäden für Web oder mobile Apps. Mit appsolut 365, Ihrem persönlichen Cockpit in der Cloud.'
      : 'Business portal to manage your enterprise processes of content management. Manage sites, blogs, documentation and guidelines for web or mobile apps. With appsolut 365, your personal cockpit in the cloud.';
  } else if (appSelected === Enum.ApplicationModule.Campus) {
    labelHeroSubTitle = isDE
      ? 'LEARN - Kursverwaltung'
      : 'LEARN - Course Management';
    labelHeroContent = isDE
      ? 'Portal zur operativen Steuerung Ihres Trainingsinstituts. Planen Sie Kurse, organisieren Sie Trainer und Ressourcen und steuern Sie die effiziente Kursabwicklung. Mit appsolut 365, Ihrem persönlichen Cockpit in der Cloud.'
      : 'Business portal to manage your training institute. Plan training courses, organize trainers and resources and enable an efficient course management. With appsolut 365, your personal cockpit in the cloud.';
  }

  // HELPER

  // EVENT HANDLER

  // DATA LOAD

  // RENDER
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              ml={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box mt={3}>
                <Typography variant="h1" color="primary">
                  {labelHeroTitle}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="h4" color="textSecondary">
                  {labelHeroSubTitle}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body1" color="textSecondary">
                  {labelHeroContent}
                </Typography>
              </Box>
              <Box mt={6}>
                <Typography variant="body1" color="textSecondary">
                  {labelHeroWelcome}
                </Typography>
                <Box mt={0}>
                  <Typography variant="h2" color="secondary">
                    {userName}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1" color="textSecondary">
                    {labelHeroContext}
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Link
                    component={RouterLink}
                    to="/app/account/organization/view"
                    underline="none"
                  >
                    <Typography variant="body2" color="textSecondary">
                      {`${labelOrganization}: ${labelOrgVal}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`${labelApplication}: ${labelAppVal}`}
                    </Typography>
                    {hasSite && (
                      <Typography variant="body2" color="textSecondary">
                        {`${labelSite}: ${labelSiteVal}`}
                      </Typography>
                    )}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/images/undraw_work_chat_erdt.svg"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
