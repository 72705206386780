import produce from 'immer';
import * as Actions from 'src/actions/types';
import * as Entities from 'src/config/Entities';

const initialState = {
  user: null,
  tenant: null,
  organization: null,
  errors: {},
  sidebarOpen: false,
  calendar: {
    taskType: 'appointment',
    query: {
      params: null,
      result: null
    },
    entityId: null,
    fetching: false,
    mode: Entities.EntityMode.view
  },
  asset: {
    entity: '',
    entityid: '',
    field: '',
    params: null
  }
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    // USER ENVIRONMENT
    case Actions.AUTH_LOGIN_SUCCESS:
    case Actions.AUTH_SILENT_LOGIN: {
      const { user, tenant, organization } = action.payload;
      return produce(state, draft => {
        draft.user = user;
        draft.tenant = tenant;
        draft.organization = organization;
      });
    }
    case Actions.AUTH_LOGOUT:
    case Actions.AUTH_LOGIN_REQUEST: {
      return produce(state, draft => {
        draft.user = null;
        draft.tenant = null;
        draft.organization = null;
      });
    }
    case Actions.AUTH_PROFILE_UPDATE: {
      const { user } = action.payload;
      return produce(state, draft => {
        draft.user = user;
      });
    }
    case Actions.APP_ORGANIZATION_SELECT: {
      const { tenant, organization } = action.payload;
      return produce(state, draft => {
        draft.tenant = tenant;
        draft.organization = organization;
      });
    }

    // ERRORS
    case Actions.ERRORS_ADD: {
      const { errors } = action.payload;
      return produce(state, draft => {
        const newErrors = {
          ...state.errors,
          ...errors
        };
        draft.errors = { ...newErrors };
      });
    }
    case Actions.ERRORS_REMOVE: {
      const { errorId } = action.payload;
      return produce(state, draft => {
        let errors = { ...state.errors };
        delete errors[errorId];
        draft.errors = { ...errors };
      });
    }
    case Actions.ERRORS_CLEAR: {
      return produce(state, draft => {
        draft.errors = {};
      });
    }

    // SIDEBAR
    case Actions.SIDEBAR_OPEN: {
      return produce(state, draft => {
        draft.sidebarOpen = true;
      });
    }
    case Actions.SIDEBAR_CLOSE: {
      return produce(state, draft => {
        draft.sidebarOpen = false;
      });
    }

    // CALENDAR
    case Actions.APPOINTMENT_LIST_REQUEST:
    case Actions.APPOINTMENT_LIST_REQUEST_MORE: {
      const { params } = action.payload;
      return produce(state, draft => {
        draft.calendar.taskType = 'appointment';
        draft.calendar.query.params = params;
        draft.calendar.fetching = true;
      });
    }
    case Actions.APPOINTMENT_LIST_RECEIVE: {
      const { pageinfo } = action.payload;
      return produce(state, draft => {
        draft.calendar.query.result = pageinfo;
        draft.calendar.fetching = false;
      });
    }
    case Actions.APPOINTMENT_LIST_APPEND: {
      const { pageinfo } = action.payload;
      return produce(state, draft => {
        const actualSelected = state.calendar.query
          ? state.calendar.query.selected
          : 0;
        const appendSelected = pageinfo ? pageinfo.selected : 0;
        const newPageInfo = {
          ...pageinfo,
          selected: actualSelected + appendSelected
        };
        draft.calendar.query.result = newPageInfo;
        draft.calendar.fetching = false;
      });
    }
    case Actions.APPOINTMENT_MODE: {
      const { mode } = action.payload;
      return produce(state, draft => {
        draft.calendar.mode = mode;
      });
    }
    case Actions.APPOINTMENT_SELECT: {
      const { entityId } = action.payload;
      return produce(state, draft => {
        draft.calendar.taskType = 'appointment';
        draft.calendar.entityId = entityId;
      });
    }
    case Actions.APPOINTMENT_REQUEST: {
      return produce(state, draft => {
        draft.calendar.taskType = 'appointment';
        draft.calendar.fetching = true;
      });
    }
    case Actions.APPOINTMENT_RECEIVE:
    case Actions.APPOINTMENT_ADD:
    case Actions.APPOINTMENT_UPDATE: {
      const { entity } = action.payload;
      return produce(state, draft => {
        draft.calendar.entityId = entity._id;
        draft.calendar.fetching = false;
      });
    }
    case Actions.APPOINTMENT_DELETE: {
      return produce(state, draft => {
        draft.calendar.entityId = null;
        draft.calendar.fetching = false;
      });
    }
    case Actions.APPOINTMENT_FINISHED: {
      return produce(state, draft => {
        draft.calendar.fetching = false;
      });
    }

    // ASSET
    case Actions.APP_ASSET_REQUEST: {
      const { params } = action.payload;
      return produce(state, draft => {
        draft.asset = {
          entity: params.entity,
          entityid: params.entityid,
          field: params.field,
          params: null
        };
      });
    }
    case Actions.APP_ASSET_RECEIVE: {
      const { asset } = action.payload;
      return produce(state, draft => {
        draft.asset = {
          entity: state.asset.entity,
          entityid: state.asset.entityid,
          field: state.asset.field,
          params: asset
        };
      });
    }
    case Actions.APP_ASSET_CLEAR: {
      return produce(state, draft => {
        draft.asset = {
          entity: '',
          entityid: '',
          field: '',
          params: null
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default appReducer;
