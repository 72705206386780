/*eslint eqeqeq:0*/
/*eslint no-useless-escape:0*/

/**
 * SYNC functions
 */

export const isDefined = (value) => {
  const isDefined = !(
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
  return isDefined;
};

export const hasProperty = (entity, key) => {
  if (entity && key && entity.hasOwnProperty(key)) {
    return true;
  }
  return false;
};

export const isEmpty = (value) => {
  const isEmpty =
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);
  return isEmpty;
};
