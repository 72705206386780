import { UserPlus as RegisterIcon } from 'react-feather';
import { Users as AttendeeIcon } from 'react-feather';
import { Disc as BusinessPartnerIcon } from 'react-feather';
import { Trello as CalendarIcon } from 'react-feather';
import { Grid as CategoryIcon } from 'react-feather';
import { Users as ContactIcon } from 'react-feather';
import { Monitor as DeviceIcon } from 'react-feather';
import { Award as GradeIcon } from 'react-feather';
import { Layers as GroupIcon } from 'react-feather';
import { Circle as InstituteIcon } from 'react-feather';
import { MapPin as LocationIcon } from 'react-feather';
import { Users as PersonIcon } from 'react-feather';
import { Edit as PlanningIcon } from 'react-feather';
import { Box as ProductIcon } from 'react-feather';
import { Maximize as ProjectIcon } from 'react-feather';
import * as Enum from 'src/config/Enumerations';

export const getNavigationTree = t => {
  const navConfig = [
    //#region SEMESTER
    {
      subheader: t('navigation2121:Semester.Group'),
      access: Enum.AccessLevel.Member,
      items: [
        {
          title: t('navigation2121:Semester.Management.Header'),
          access: Enum.AccessLevel.Member,
          icon: CalendarIcon,
          href: '/app/semester/calendar'
        },
        {
          title: t('navigation2121:Semester.Plan.Header'),
          access: Enum.AccessLevel.User,
          icon: PlanningIcon,
          href: '/app/semester/plan'
        },
        {
          title: t('navigation2121:Semester.Semester.Header'),
          access: Enum.AccessLevel.User,
          icon: ProjectIcon,
          items: [
            {
              title: t('navigation2121:Semester.Semester.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/semesters'
            },
            {
              title: t('navigation2121:Semester.Semester.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/semester/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Semester.JobCat.Header'),
          access: Enum.AccessLevel.User,
          icon: CategoryIcon,
          items: [
            {
              title: t('navigation2121:Semester.JobCat.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/jobcats'
            },
            {
              title: t('navigation2121:Semester.JobCat.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/jobcat/create',
              exact: true
            }
          ]
        }
      ]
    },
    //#endregion

    //#region STUDENT
    {
      subheader: t('navigation2121:Student.Group'),
      access: Enum.AccessLevel.Member,
      items: [
        {
          title: t('navigation2121:Student.Registration.Header'),
          access: Enum.AccessLevel.User,
          icon: RegisterIcon,
          items: [
            {
              title: t('navigation2121:Student.Registration.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/salescontracts'
            },
            {
              title: t('navigation2121:Student.Registration.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/salescontract/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Student.Attendee.Header'),
          access: Enum.AccessLevel.Member,
          icon: AttendeeIcon,
          items: [
            /*
            {
              title: t('navigation2121:Student.Attendee.Calendar'),
              access: Enum.AccessLevel.Member,
              href: '/app/attendee/calendar'
            },
            */
            {
              title: t('navigation2121:Student.Attendee.Collection'),
              access: Enum.AccessLevel.Member,
              href: '/app/attendees'
            },
            {
              title: t('navigation2121:Student.Attendee.Add'),
              access: Enum.AccessLevel.Member,
              href: '/app/attendee/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Student.AttendeeCat.Header'),
          access: Enum.AccessLevel.User,
          icon: CategoryIcon,
          items: [
            {
              title: t('navigation2121:Student.AttendeeCat.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/attendeecats'
            },
            {
              title: t('navigation2121:Student.AttendeeCat.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/attendeecat/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Student.Grade.Header'),
          access: Enum.AccessLevel.User,
          icon: GradeIcon,
          items: [
            {
              title: t('navigation2121:Student.Grade.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/grades'
            },
            {
              title: t('navigation2121:Student.Grade.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/grade/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Student.Institute.Header'),
          access: Enum.AccessLevel.User,
          icon: InstituteIcon,
          items: [
            {
              title: t('navigation2121:Student.Institute.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/institutes'
            },
            {
              title: t('navigation2121:Student.Institute.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/institute/create',
              exact: true
            }
          ]
        }
      ]
    },
    //#endregion

    //#region RESOURCE
    {
      subheader: t('navigation2121:Resource.Group'),
      access: Enum.AccessLevel.Member,
      items: [
        {
          title: t('navigation2121:Resource.Person.Header'),
          access: Enum.AccessLevel.User,
          icon: PersonIcon,
          items: [
            /*
            {
              title: t('navigation2121:Resource.Person.Calendar'),
              access: Enum.AccessLevel.User,
              href: '/app/person/calendar'
            },
            */
            {
              title: t('navigation2121:Resource.Person.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/persons'
            },
            {
              title: t('navigation2121:Resource.Person.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/person/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Resource.Location.Header'),
          access: Enum.AccessLevel.Member,
          icon: LocationIcon,
          items: [
            /*
            {
              title: t('navigation2121:Resource.Location.Calendar'),
              access: Enum.AccessLevel.Member,
              href: '/app/location/calendar'
            },
            */
            {
              title: t('navigation2121:Resource.Location.Collection'),
              access: Enum.AccessLevel.Member,
              href: '/app/locations'
            },
            {
              title: t('navigation2121:Resource.Location.Add'),
              access: Enum.AccessLevel.Member,
              href: '/app/location/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Resource.Device.Header'),
          access: Enum.AccessLevel.Member,
          icon: DeviceIcon,
          items: [
            /*
            {
              title: t('navigation2121:Resource.Device.Calendar'),
              access: Enum.AccessLevel.Member,
              href: '/app/device/calendar'
            },
            */
            {
              title: t('navigation2121:Resource.Device.Collection'),
              access: Enum.AccessLevel.Member,
              href: '/app/devices'
            },
            {
              title: t('navigation2121:Resource.Device.Add'),
              access: Enum.AccessLevel.Member,
              href: '/app/device/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Resource.ResourceGroup.Header'),
          access: Enum.AccessLevel.User,
          icon: GroupIcon,
          items: [
            {
              title: t('navigation2121:Resource.ResourceGroup.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/resourcegroups'
            },
            {
              title: t('navigation2121:Resource.ResourceGroup.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/resourcegroup/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Resource.ResourceCat.Header'),
          access: Enum.AccessLevel.User,
          icon: CategoryIcon,
          items: [
            {
              title: t('navigation2121:Resource.ResourceCat.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/resourcecats'
            },
            {
              title: t('navigation2121:Resource.ResourceCat.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/resourcecat/create',
              exact: true
            }
          ]
        }
      ]
    },
    //#endregion

    //#region CRM
    {
      subheader: t('navigation2121:CRM.Group'),
      access: Enum.AccessLevel.User,
      items: [
        {
          title: t('navigation2121:CRM.BusinessPartner.BusinessPartner'),
          access: Enum.AccessLevel.User,
          icon: BusinessPartnerIcon,
          href: '/app/businesspartners'
        },
        {
          title: t('navigation2121:CRM.BusinessPartner.Group'),
          access: Enum.AccessLevel.User,
          icon: GroupIcon,
          href: '/app/businesspartnergroups'
        },
        {
          title: t('navigation2121:CRM.BusinessPartner.Cat'),
          access: Enum.AccessLevel.User,
          icon: CategoryIcon,
          href: '/app/businesspartnercats'
        },
        {
          title: t('navigation2121:CRM.Contact'),
          access: Enum.AccessLevel.User,
          icon: ContactIcon,
          href: '/app/contacts'
        }
      ]
    },
    //#endregion

    //#region PRODUCT
    {
      subheader: t('navigation2121:Product.Group'),
      access: Enum.AccessLevel.User,
      items: [
        {
          title: t('navigation2121:Product.Product.Header'),
          access: Enum.AccessLevel.User,
          icon: ProductIcon,
          items: [
            {
              title: t('navigation2121:Product.Product.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/products'
            },
            {
              title: t('navigation2121:Product.Product.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/product/create',
              exact: true
            }
          ]
        },
        {
          title: t('navigation2121:Product.ProductGroup.Header'),
          access: Enum.AccessLevel.User,
          icon: GroupIcon,
          items: [
            {
              title: t('navigation2121:Product.ProductGroup.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/productgroups'
            },
            {
              title: t('navigation2121:Product.ProductGroup.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/productgroup/create',
              exact: true
            }
          ]
        }
        /*
        {
          title: t('navigation2121:Product.Pricing.Header'),
          access: Enum.AccessLevel.User,
          icon: PricingIcon,
          items: [
            {
              title: t('navigation2121:Product.Pricing.Collection'),
              access: Enum.AccessLevel.User,
              href: '/app/pricings'
            },
            {
              title: t('navigation2121:Product.Pricing.Add'),
              access: Enum.AccessLevel.User,
              href: '/app/pricing/create',
              exact: true
            }
          ]
        }
        */
      ]
    }
    //#endregion
  ];

  return navConfig;
};
