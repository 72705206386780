import * as ApiService from 'src/utils/axiosService';

class EntityService {
  getMe = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyStatus = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/status')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyTenant = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/tenant')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyTenants = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/tenants')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyMember = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/member')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyOrganization = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/organization')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyOrganizations = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/organizations')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyDelegate = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/delegate')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyDelegates = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/delegates')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyContact = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/contact')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMySite = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/site')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  getMyDelegateSite = () => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .get('/me/delegatesite')
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMe = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMyTenant = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/tenant`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMyMember = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/member`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMyOrganization = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/organization`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMyDelegate = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/delegate`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMyContact = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/contact`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMySite = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/site`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };

  UpdateMyDelegateSite = payload => {
    return new Promise(function(resolve, reject) {
      ApiService.toAPI
        .patch(`/me/delegatesite`, payload)
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.response && err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  };
}

const entityService = new EntityService();
export default entityService;
