import * as Enums from 'src/config/Enumerations';
import NoImage from 'src/assets/images/no-image.png';
import DefaultThumb from 'src/assets/images/thumb-document.png';
import ExternalThumb from 'src/assets/images/thumb-external.png';

//---------------------------
// DEFINITIONS
//---------------------------

const imageExt = [
  'png',
  'jpg',
  'jpe',
  'jpeg',
  'jp2',
  'gif',
  'webp',
  'bmp',
  'ico',
  'tif',
  'tiff',
  'psd'
];
const pdfExt = ['pdf'];

//---------------------------
// MAPPING
//---------------------------

export const getAssetFromProvider = (cloudName, params = {}) => {
  const contextObject = params && params.context ? params.context : null;
  const customObject = contextObject ? contextObject.custom : null;
  const hasCustom = !!customObject;

  const derivedList = params ? params.derived : [];
  const hasDerived = !!derivedList && derivedList.length > 0;
  const derived = hasDerived ? derivedList[0] : {};
  const valUrl = hasDerived ? derived.url : params.url;
  const valSecureUrl = hasDerived ? derived.secure_url : params.secure_url;
  const result = {
    cloudinaryAssetData: true,
    cloudName: cloudName,
    publicId: params.public_id,
    originalHeight: params.height,
    originalWidth: params.width,
    resourceType: params.resource_type,
    type: params.type,
    url: valUrl,
    secureUrl: valSecureUrl,
    version: params.version,
    accessMode: params.access_mode,
    format: params.format,
    tags: params.tags,
    metadata: params.metadata,
    bytes: params.bytes,
    duration: params.duration,
    createdAt: params.created_at,
    alt: hasCustom && customObject.alt ? customObject.alt : '',
    caption: hasCustom && customObject.caption ? customObject.caption : '',
    source: hasCustom && customObject.source ? customObject.source : ''
  };
  return result;
};

//---------------------------
// UTILS
//---------------------------

export const getMediaSettings = organization => {
  const mediachannel = organization ? organization.mediachannel : {};
  const result = {
    provider: mediachannel
      ? mediachannel.provider
      : Enums.MediaProvider.Cloudinary,
    cloudName: mediachannel ? mediachannel.cloudName : '',
    apiKey: mediachannel ? mediachannel.apiKey : '',
    apiSecret: mediachannel ? mediachannel.apiSecret : '',
    folder: mediachannel ? mediachannel.folder : '',
    scope: mediachannel ? mediachannel.scope : '',
    uploadPreset: mediachannel ? mediachannel.uploadPreset : ''
  };
  return result;
};

export const getThumbnailFromAttachment = (linkextern, asset) => {
  let result = NoImage;

  const isExtern = !!linkextern;

  if (isExtern) {
    result = ExternalThumb;
  } else if (asset) {
    const urlBase = asset.secureUrl;
    if (urlBase) {
      const formatBase = asset.format;
      const formatLower = formatBase ? formatBase.toLowerCase() : '';
      if (imageExt.includes(formatLower)) {
        const thumb = urlBase.replace('/upload/', '/upload/w_500/');
        result = thumb;
      } else if (pdfExt.includes(formatLower)) {
        const urlWidth = urlBase.replace('/upload/', '/upload/w_500/');
        const thumb = urlWidth.replace('.pdf', '.png');
        result = thumb;
      } else {
        result = DefaultThumb;
      }
    }
  }
  return result;
};
