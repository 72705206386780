/**
 * ENUMS
 */

module.exports.AboInterval = {
  Month: 'Month',
  Year: 'Year',
  Perpetual: 'Perpetual'
};

module.exports.AccessLevel = {
  User: 'User',
  Member: 'Member',
  Guest: 'Guest',
  Partner: 'Partner',
  All: 'All'
};

module.exports.AccessType = {
  Full: 'Full',
  Editor: 'Editor',
  None: 'None'
};

module.exports.ApplicationModule = {
  Core: 'Core',
  Sales: 'Sales',
  Content: 'Content',
  Campus: 'Campus',
  Booking: 'Booking',
  Project: 'Project',
  Service: 'Service',
  Event: 'Event',
  Newsletter: 'Newsletter',
  Billing: 'Billing'
};

module.exports.ApplicationScope = {
  Graph: 'Graph',
  Admin: 'Admin',
  Manage: 'Manage'
};

module.exports.ApplicationType = {
  Client: 'Client',
  Site: 'Site',
  Custom: 'Custom'
};

module.exports.ApproverType = {
  Department: 'Department',
  Project: 'Project'
};

module.exports.BaseUnitType = {
  Hour: 'Hour',
  Task: 'Task'
};

module.exports.BillingInterval = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  TwoMonths: 'TwoMonths',
  Quarter: 'Quarter',
  HalfYear: 'HalfYear',
  Year: 'Year'
};

module.exports.BusinessType = {
  Sales: 'Sales',
  Purchase: 'Purchase'
};

module.exports.ChannelCategory = {
  Office: 'Office',
  Erp: 'Erp',
  Newsletter: 'Newsletter',
  Webshop: 'Webshop',
  Other: 'Other'
};

module.exports.ChannelType = {
  Custom: 'Custom',
  Office365: 'Office365',
  Dyn365BC: 'Dyn365BC',
  FreeFinance: 'FreeFinance',
  Shopify: 'Shopify',
  Mailjet: 'Mailjet'
};

module.exports.ClientType = {
  Web: 'Web',
  Mobile: 'Mobile',
  Api: 'Api'
};

module.exports.DatasetType = {
  Avatar: 'Avatar',
  Portfolio: 'Portfolio',
  Testimonial: 'Testimonial'
};

module.exports.DelegateType = {
  User: 'User',
  Member: 'Member',
  Guest: 'Guest',
  Partner: 'Partner'
};

module.exports.DeploymentHoster = {
  None: 'None',
  Netlify: 'Netlify',
  Gatsby: 'Gatsby',
  Vercel: 'Vercel'
};

module.exports.DeploymentStatus = {
  None: 'None',
  Success: 'Success',
  Error: 'Error'
};

module.exports.DocumentStatus = {
  Draft: 'Draft',
  Open: 'Open',
  Fulfilled: 'Fulfilled',
  Closed: 'Closed',
  Canceled: 'Canceled'
};

module.exports.DocLineStatus = {
  Draft: 'Draft',
  Open: 'Open',
  Fulfilled: 'Fulfilled',
  Closed: 'Closed',
  Canceled: 'Canceled'
};

module.exports.DocLineType = {
  Product: 'Product',
  SubProduct: 'SubProduct',
  Header: 'Header',
  Text: 'Text'
};

module.exports.EditorRole = {
  None: 'None',
  Editor: 'Editor',
  Publisher: 'Publisher',
  Admin: 'Admin'
};

module.exports.EmailStatus = {
  Draft: 'Draft',
  Sent: 'Sent',
  Spam: 'Spam',
  DeletedUnopened: 'DeletedUnopened',
  Opened: 'Opened',
  Link: 'Link',
  Archived: 'Archived',
  Deleted: 'Deleted'
};

module.exports.EmailTemplate = {
  Basic: 'Basic',
  Notification: 'Notification',
  Confirmation: 'Confirmation'
};

module.exports.EmailType = {
  Transaction: 'Transaction',
  Newsletter: 'Newsletter'
};

module.exports.Environment = {
  None: 'None',
  Web: 'Web',
  Mobile: 'Mobile'
};

module.exports.Gender = {
  Undefined: 'Undefined',
  Male: 'Male',
  Female: 'Female',
  Diverse: 'Diverse'
};

module.exports.InvoicingDue = {
  Before: 'Before',
  First: 'First',
  Last: 'Last',
  After: 'After'
};

module.exports.InvoicingInterval = {
  Month: 'Month',
  Quarter: 'Quarter',
  HalfYear: 'HalfYear',
  Year: 'Year',
  Period: 'Period'
};

module.exports.IterationStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Closing: 'Closing',
  Closed: 'Closed'
};

module.exports.JobStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Closing: 'Closing',
  Closed: 'Closed'
};

module.exports.LicenseArea = {
  Organisation: 'Organisation',
  Application: 'Application',
  MobilePortal: 'MobilePortal',
  WebPortal: 'WebPortal',
  Connector: 'Connector',
  Special: 'Special'
};

module.exports.LicenseExtension = {
  Guide: 'Guide',
  KnowledgeBase: 'KnowledgeBase',
  Community: 'Community',
  Booking: 'Booking',
  Service: 'Service'
};

module.exports.LicenseScope = {
  Organisation: 'Organisation',
  Site: 'Site',
  Application: 'Application',
  User: 'User',
  Member: 'Member',
  Guest: 'Guest',
  Partner: 'Partner'
};

module.exports.LicenseType = {
  Plan: 'Plan',
  Service: 'Service',
  Extension: 'Extension'
};

module.exports.Marketplace = {
  None: 'None',
  AppStore: 'AppStore',
  PlayStore: 'PlayStore'
};

module.exports.MediaProvider = {
  None: 'None',
  Cloudinary: 'Cloudinary'
};

// Lowercase for external use
module.exports.MediaScope = {
  upload: 'upload'
};

// Lowercase for external use
module.exports.MediaType = {
  auto: 'auto',
  image: 'image',
  video: 'video',
  raw: 'raw'
};

module.exports.MenuType = {
  Header: 'Header',
  Footer: 'Footer',
  Main: 'Main',
  Custom: 'Custom'
};

module.exports.MenuItemType = {
  None: 'None',
  Extern: 'Extern',
  Home: 'Home',
  AreaHome: 'AreaHome',
  Page: 'Page',
  Author: 'Author',
  Blog: 'Blog',
  Doc: 'Doc',
  Event: 'Event',
  Forum: 'Forum',
  Guideline: 'Guideline',
  Tag: 'Tag'
};

module.exports.MessageStatus = {
  Draft: 'Draft',
  Sent: 'Sent',
  Read: 'Read',
  Closed: 'Closed'
};

module.exports.MessageType = {
  Message: 'Message',
  Invitation: 'Invitation',
  Survey: 'Survey'
};

module.exports.NotifyChannel = {
  Notification: 'Notification',
  Mail: 'Mail'
};

// Lowercase for external use
module.exports.NotifyPriority = {
  Default: 'default',
  Normal: 'normal',
  High: 'high'
};

// Lowercase for external use
module.exports.NotifySound = {
  Default: 'default'
};

module.exports.NotifyStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Running: 'Running',
  Finished: 'Finished',
  Closed: 'Closed'
};

module.exports.PageType = {
  Single: 'Single',
  Post: 'Post',
  Event: 'Event',
  Article: 'Article',
  Posting: 'Posting',
  Action: 'Action'
};

module.exports.PaymentType = {
  Bank: 'Bank',
  Bar: 'Bar'
};

module.exports.Platform = {
  iOS: 'iOS',
  Android: 'Android'
};

module.exports.PreferedContact = {
  Phone: 'Phone',
  Mobile: 'Mobile',
  Sms: 'Sms',
  Message: 'Message',
  Email: 'Email',
  Fax: 'Fax'
};

module.exports.PricingType = {
  Sales: 'Sales',
  Purchase: 'Purchase'
};

module.exports.ProductType = {
  Inventory: 'Inventory',
  NonInventory: 'NonInventory',
  Service: 'Service',
  Subscription: 'Subscription'
};

module.exports.Priority = {
  Standard: 'Standard',
  High: 'High',
  Low: 'Low'
};

module.exports.ProjectStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Closing: 'Closing',
  Closed: 'Closed'
};

module.exports.ReceiverType = {
  To: 'To',
  Cc: 'Cc',
  Bcc: 'Bcc'
};

module.exports.ServiceInterval = {
  Month: 'Month',
  Year: 'Year'
};

module.exports.StepType = {
  None: 'None',
  Coordination: 'Coordination',
  Meeting: 'Meeting',
  Message: 'Message',
  Talk: 'Talk',
  Task: 'Task',
  Team: 'Team',
  Extern: 'Extern'
};

module.exports.SubscriptionInterval = {
  Month: 'Month',
  Year: 'Year',
  Perpetual: 'Perpetual'
};

module.exports.SyncStatus = {
  Ok: 'Ok',
  Error: 'Error',
  Manual: 'Manual',
  NotSet: 'NotSet'
};

module.exports.Template = {
  Standard: 'Standard',
  Contact: 'Contact',
  Data: 'Data',
  Newsletter: 'Newsletter',
  Message: 'Message',
  Booking: 'Booking',
  Issue: 'Issue'
};

module.exports.TriggerAction = {
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete',
  Publish: 'Publish',
  Unpublish: 'Unpublish'
};

module.exports.TriggerType = {
  Record: 'Record',
  File: 'File',
  Deployment: 'Deployment'
};

module.exports.UserType = {
  None: 'None',
  BackendAdmin: 'BackendAdmin',
  BackendUser: 'BackendUser',
  BackendMember: 'BackendMember',
  FrontendMember: 'FrontendMember',
  FrontendGuest: 'FrontendGuest',
  FrontendPartner: 'FrontendPartner'
};

module.exports.Visibility = {
  Public: 'Public',
  Community: 'Community',
  Team: 'Team',
  Office: 'Office',
  Admin: 'Admin'
};

/**
 * OBJECT kinds
 */

module.exports.JobKind = {
  Bug: 'Bug',
  Case: 'Case',
  Complaint: 'Complaint',
  Course: 'Course',
  Feature: 'Feature',
  Idea: 'Idea',
  Requirement: 'Requirement',
  Service: 'Service',
  Show: 'Show',
  Tour: 'Tour'
};

module.exports.ProjectKind = {
  Abonnement: 'Abonnement',
  Business: 'Business',
  Event: 'Event',
  Semester: 'Semester',
  Solution: 'Solution',
  Travel: 'Travel'
};

module.exports.PurchaseDocKind = {
  Quote: 'PurchaseQuote',
  Order: 'PurchaseOrder',
  Invoice: 'PurchaseInvoice'
};

module.exports.ResourceKind = {
  Person: 'Person',
  Location: 'Location',
  Device: 'Device'
};

module.exports.SalesDocKind = {
  Quote: 'SalesQuote',
  Order: 'SalesOrder',
  Invoice: 'SalesInvoice'
};

module.exports.TaskKind = {
  Lesson: 'Lesson',
  Seat: 'Seat',
  Session: 'Session'
};
