import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createMediaLibrary } from 'src/components/Media/MediaLibraryWidget';
import { requestAsset } from 'src/actions/appActions';
import { receiveAsset } from 'src/actions/appActions';
import * as Media from 'src/data/media/utils';

const MediaContext = createContext();

export function MediaProvider({ children }) {
  // PARAMS & DEFINITIONS
  const dispatch = useDispatch();

  // STATE & DATA
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [mediaManager, setMediaManager] = useState(null);
  const myOrg = useSelector(state => state.myorganization.organization);
  const mediaSet = Media.getMediaSettings(myOrg);

  // HELPER
  const imageInsertHandler = data => {
    //console.log('== Image Insert ==');
    //console.log(data);
    let assetList = [];
    data.assets.forEach(asset => {
      const appAsset = Media.getAssetFromProvider(mediaSet.cloudName, asset);
      assetList.push(appAsset);
    });
    //console.log(assetList);
    dispatch(receiveAsset(assetList));
  };
  const openMediaManager = options => {
    //console.log('== Create new Media Manager ==');
    const libraryOptions = {
      cloud_name: mediaSet.cloudName,
      api_key: mediaSet.apiKey,
      multiple: false
    };
    //console.log(libraryOptions);
    const mm = createMediaLibrary(libraryOptions, {
      insertHandler: imageInsertHandler
    });
    setSelectedOrg(myOrg);
    setMediaManager(() => mm);
    // Initial show new Media Manager
    let showOptions = {
      folder: { path: mediaSet.folder, resource_type: 'image' }
    };
    if (options) {
      showOptions = {
        ...showOptions,
        ...options
      };
    }
    mm.show(showOptions);
  };

  // EVENT HANDLER
  const handleShowManager = (params, options) => {
    //console.log('=== MEDIA SHOW ===');
    //console.log(params);
    dispatch(requestAsset(params));

    const previousOrgId = selectedOrg ? selectedOrg._id : '';
    const actualOrgId = myOrg ? myOrg._id : '';
    if (!mediaManager || !(previousOrgId === actualOrgId)) {
      //console.log(`Create New Media Manager`);
      //console.log(`Media Manager defined: ${!!mediaManager}`);
      //console.log(`Previous Org: ${previousOrgId}`);
      //console.log(`Actual Org: ${actualOrgId}`);
      openMediaManager(options);
    } else if (mediaManager) {
      let showOptions = {};
      if (options) {
        showOptions = {
          ...showOptions,
          ...options
        };
      }
      mediaManager.show(showOptions);
    }
  };
  const handleHideManager = () => {
    //console.log('=== MEDIA HIDE ===');
    if (mediaManager) {
      mediaManager.hide();
    }
  };

  // DATA LOAD

  // RENDER
  return (
    <MediaContext.Provider
      value={{
        showManager: handleShowManager,
        hideManager: handleHideManager
      }}
    >
      {children}
    </MediaContext.Provider>
  );
}

MediaProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const MediaConsumer = MediaContext.Consumer;

export default MediaContext;
