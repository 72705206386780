import moment from 'moment';
import slugify from 'slugify';
import * as Validation from 'src/utils/validation';
import * as Enums from 'src/config/Enumerations';

//---------------------------
// TRANSFORMATIONS
//---------------------------

export const getUniqueSlugFromString = value => {
  const randomPostfix = generateRandomString(6);
  const baseString = `${value} ${randomPostfix}`;
  return slugify(baseString);
};

export const getSlugFromString = value => {
  return slugify(value);
};

export const getUserTypeFromDelegate = Delegate => {
  const isAdmin = Delegate ? Delegate.isadmin : false;
  const delegateType = Delegate ? Delegate.delegatetype : '';
  const accessType = Delegate ? Delegate.accesstype : '';
  return getUserTypeFromParams(delegateType, accessType, isAdmin);
};

export const getUserTypeFromParams = (DelegateType, AccessType, IsAdmin) => {
  const isAdmin = !!IsAdmin;
  const isDelegateUser = DelegateType === Enums.DelegateType.User;
  const isDelegateMember = DelegateType === Enums.DelegateType.Member;
  const isDelegatePartner = DelegateType === Enums.DelegateType.Partner;
  const isDelegateGuest = DelegateType === Enums.DelegateType.Guest;
  const isAccessFull = AccessType === Enums.AccessType.Full;
  const isAccessEditor = AccessType === Enums.AccessType.Editor;

  if (isDelegateUser && isAdmin) return Enums.UserType.BackendAdmin;
  if (isDelegateUser && isAccessFull) return Enums.UserType.BackendUser;
  if (isDelegateUser && isAccessEditor) return Enums.UserType.BackendMember;
  if (isDelegateUser) return Enums.UserType.FrontendMember;
  if (isDelegateMember) return Enums.UserType.FrontendMember;
  if (isDelegatePartner) return Enums.UserType.FrontendPartner;
  if (isDelegateGuest) return Enums.UserType.FrontendGuest;

  return Enums.UserType.None;
};

export const isBackendUser = DelegateType => {
  const isBackend = DelegateType === Enums.DelegateType.User;
  return isBackend;
};

//---------------------------
// CALCULATIONS
//---------------------------

export const getGrossFromNet = (net, vatpercent) => {
  const gross =
    Number(net) * ((Number(100) + Number(vatpercent)) / Number(100));
  return gross;
};

export const getNetFromGross = (gross, vatpercent) => {
  const net =
    Number(gross) * (Number(100) / (Number(100) + Number(vatpercent)));
  return net;
};

export const calculateContractLineValues = (values, setFieldValue) => {
  // console.log('=== CALC ===');
  // console.log(values);

  // Get values
  const factor = values.factor ? Number(values.factor) : 1;
  const periodfactor = values.periodfactor ? Number(values.periodfactor) : 1;
  const inbaseunit = values.inbaseunit ? Number(values.inbaseunit) : 1;

  const quantity = values.quantity ? Number(values.quantity) : 0;
  const netbase = values.netbase ? Number(values.netbase) : 0;
  const grossbase = values.grossbase ? Number(values.grossbase) : 0;
  const months = values.months ? Number(values.months) : 0;
  const periodnetbase = values.periodnetbase ? Number(values.periodnetbase) : 0;
  const periodgrossbase = values.periodgrossbase
    ? Number(values.periodgrossbase)
    : 0;

  // Calculate values
  const netTotal = quantity * netbase;
  const grossTotal = quantity * grossbase;
  const weight = quantity * inbaseunit * factor;
  const periodNetTotal = quantity * months * periodnetbase;
  const periodGrossTotal = quantity * months * periodgrossbase;
  const periodWeight = quantity * inbaseunit * periodfactor;
  const periodWeightTotal = quantity * months * inbaseunit * periodfactor;

  setFieldValue('netbasetotal', netTotal);
  setFieldValue('grossbasetotal', grossTotal);
  setFieldValue('weight', weight);
  setFieldValue('periodnetbasetotal', periodNetTotal);
  setFieldValue('periodgrossbasetotal', periodGrossTotal);
  setFieldValue('periodweight', periodWeight);
  setFieldValue('periodweighttotal', periodWeightTotal);
};

//---------------------------
// GENERATORS
//---------------------------

export const generateRandomString = (length = 6) => {
  return Math.random()
    .toString(20)
    .substr(2, length);
};

export const generateBusinessPartnerCode = (length = 6) => {
  const randomStrg = generateRandomString(length);
  const codeStrg = `BP-${randomStrg}`.trim().toUpperCase();
  return codeStrg;
};

export const generateGenericCode = (length = 6) => {
  const randomStrg = generateRandomString(length);
  const codeStrg = `${randomStrg}`.trim().toUpperCase();
  return codeStrg;
};

//---------------------------
// VALIDATIONS
//---------------------------

export const isValidId = string => {
  if (Validation.isEmpty(string)) return false;
  if (typeof string !== 'string') return false;
  if (string === 'null') return false;
  if (string === 'undefined') return false;
  return string;
};

export const isObject = value => {
  if (
    (typeof value === 'object' || typeof value === 'function') &&
    value !== null
  ) {
    return true;
  }
  return false;
};

//---------------------------
// FORMATERS
//---------------------------

export const getDocumentNumber = entity => {
  const doc = entity ? entity.doc : null;

  const docNumber = doc ? doc.docnumber : '--';
  const docPrefix = doc ? doc.prefix : '';
  const docPostfix = doc ? doc.postfix : '';
  const docNumberStrg = `${docPrefix}${docNumber}${docPostfix}`;

  return docNumberStrg;
};

export const getDateString = (date, format = 'DD.MM.YYYY') => {
  const dateValue = getNullForEmptyString(date);
  const dateString = dateValue ? moment(dateValue).format(format) : '--';
  return dateString;
};

export const getIntegerString = (value, zeroStrg = '0') => {
  if (Number.isInteger(value)) {
    let intStrg = value.toLocaleString();
    if (intStrg === '0') {
      intStrg = zeroStrg;
    }
    return intStrg;
  }
  return value;
};

export const getHexColorFromInput = color => {
  if (isObject(color)) {
    return `#${color.hex}`;
  }
  return color;
};

export const getNameFromComponents = (firstname, lastname, empty = '--') => {
  let validFirst = getValidString(firstname).trim();
  let validLast = getValidString(lastname).trim();

  if (validFirst && validLast) {
    return `${validFirst} ${validLast}`;
  }
  if (validFirst) {
    return validFirst;
  }
  if (validLast) {
    return validLast;
  }
  return empty;
};

export const getNameOfEntityFromArray = (id, collection) => {
  let result = '';
  // eslint-disable-next-line
  let entity = collection.find(obj => obj.id == id);
  if (entity) {
    result = entity.name;
  }
  return result;
};

export const getNullForEmptyString = string => {
  if (string === '') return null;
  return string;
};

export const getValidObjectId = string => {
  const nulled = getNullForEmptyString(string);
  return nulled;
};

export const getValidString = (string, emptyString = '') => {
  if (Validation.isEmpty(string)) return emptyString;
  if (typeof string !== 'string') return emptyString;
  return string;
};
