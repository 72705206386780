import { lazy } from 'react';

const RoutesConfig = [
  //#region SEMESTER

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/semester/calendar',
    component: lazy(() => import('src/views/calendar/CourseCalendar'))
  },
  {
    exact: true,
    path: '/app/semester/plan',
    component: lazy(() => import('src/views/calendar/PlanCourseCalendar'))
  },
  //#endregion

  //#region -- SEMESTERS
  {
    exact: true,
    path: [
      '/app/semesters/label/:customLabel',
      '/app/semesters/:systemLabel',
      '/app/semesters'
    ],
    component: lazy(() => import('src/views/projects/semesters'))
  },
  {
    exact: true,
    path: '/app/semester/edit/:entityId',
    component: lazy(() => import('src/views/projects/semesters/EditView'))
  },
  {
    exact: true,
    path: '/app/semester/edit-header/:entityId',
    component: lazy(() =>
      import('src/views/projects/semesters/EditView/Header')
    )
  },
  {
    exact: true,
    path: '/app/semester/edit-footer/:entityId',
    component: lazy(() =>
      import('src/views/projects/semesters/EditView/Footer')
    )
  },
  {
    exact: true,
    path: '/app/semester/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/projects/semesters/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/semester/view/:entityId',
    component: lazy(() => import('src/views/projects/semesters/DetailsView'))
  },
  {
    exact: true,
    path: '/app/semester/create',
    component: lazy(() => import('src/views/projects/semesters/CreateView'))
  },
  //#endregion

  //#region -- JOB-CATS
  {
    exact: true,
    path: [
      '/app/jobcats/label/:customLabel',
      '/app/jobcats/:systemLabel',
      '/app/jobcats'
    ],
    component: lazy(() => import('src/views/jobcats'))
  },
  {
    exact: true,
    path: '/app/jobcat/edit/:entityId',
    component: lazy(() => import('src/views/jobcats/EditView'))
  },
  {
    exact: true,
    path: '/app/jobcat/edit-description/:entityId',
    component: lazy(() => import('src/views/jobcats/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/jobcat/view/:entityId',
    component: lazy(() => import('src/views/jobcats/DetailsView'))
  },
  {
    exact: true,
    path: '/app/jobcat/create',
    component: lazy(() => import('src/views/jobcats/CreateView'))
  },
  //#endregion

  //#endregion

  //#region STUDENT

  //#region -- SALES-CONTRACTS
  {
    exact: true,
    path: [
      '/app/salescontracts/label/:customLabel',
      '/app/salescontracts/:systemLabel',
      '/app/salescontracts'
    ],
    component: lazy(() => import('src/views/documents/salescontracts'))
  },
  {
    exact: true,
    path: '/app/salescontract/edit/:entityId',
    component: lazy(() => import('src/views/documents/salescontracts/EditView'))
  },
  {
    exact: true,
    path: '/app/salescontract/edit-address/:entityId',
    component: lazy(() =>
      import('src/views/documents/salescontracts/EditView/Address')
    )
  },
  {
    exact: true,
    path: '/app/salescontract/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/documents/salescontracts/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/salescontract/view/:entityId',
    component: lazy(() =>
      import('src/views/documents/salescontracts/DetailsView')
    )
  },
  {
    exact: true,
    path: '/app/salescontract/create',
    component: lazy(() =>
      import('src/views/documents/salescontracts/CreateView')
    )
  },
  //#endregion

  //#region -- ATTENDEES
  {
    exact: true,
    path: '/app/attendee/calendar',
    component: lazy(() => import('src/views/calendar/AttendeesCourseCalendar'))
  },
  {
    exact: true,
    path: [
      '/app/attendees/label/:customLabel',
      '/app/attendees/:systemLabel',
      '/app/attendees'
    ],
    component: lazy(() => import('src/views/attendees'))
  },
  {
    exact: true,
    path: '/app/attendee/edit/:entityId',
    component: lazy(() => import('src/views/attendees/EditView'))
  },
  {
    exact: true,
    path: '/app/attendee/edit-address/:entityId',
    component: lazy(() => import('src/views/attendees/EditView/Address'))
  },
  {
    exact: true,
    path: '/app/attendee/edit-additional/:entityId',
    component: lazy(() => import('src/views/attendees/EditView/Additional'))
  },
  {
    exact: true,
    path: '/app/attendee/edit-bio/:entityId',
    component: lazy(() => import('src/views/attendees/EditView/Bio'))
  },
  {
    exact: true,
    path: '/app/attendee/edit-description/:entityId',
    component: lazy(() => import('src/views/attendees/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/attendee/view/:entityId',
    component: lazy(() => import('src/views/attendees/DetailsView'))
  },
  {
    exact: true,
    path: '/app/attendee/create',
    component: lazy(() => import('src/views/attendees/CreateView'))
  },
  //#endregion

  //#region -- ATTENDEE-CATS
  {
    exact: true,
    path: [
      '/app/attendeecats/label/:customLabel',
      '/app/attendeecats/:systemLabel',
      '/app/attendeecats'
    ],
    component: lazy(() => import('src/views/attendeecats'))
  },
  {
    exact: true,
    path: '/app/attendeecat/edit/:entityId',
    component: lazy(() => import('src/views/attendeecats/EditView'))
  },
  {
    exact: true,
    path: '/app/attendeecat/edit-description/:entityId',
    component: lazy(() => import('src/views/attendeecats/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/attendeecat/view/:entityId',
    component: lazy(() => import('src/views/attendeecats/DetailsView'))
  },
  {
    exact: true,
    path: '/app/attendeecat/create',
    component: lazy(() => import('src/views/attendeecats/CreateView'))
  },
  //#endregion

  //#region -- GRADES
  {
    exact: true,
    path: [
      '/app/grades/label/:customLabel',
      '/app/grades/:systemLabel',
      '/app/grades'
    ],
    component: lazy(() => import('src/views/grades'))
  },
  {
    exact: true,
    path: '/app/grade/edit/:entityId',
    component: lazy(() => import('src/views/grades/EditView'))
  },
  {
    exact: true,
    path: '/app/grade/edit-description/:entityId',
    component: lazy(() => import('src/views/grades/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/grade/view/:entityId',
    component: lazy(() => import('src/views/grades/DetailsView'))
  },
  {
    exact: true,
    path: '/app/grade/create',
    component: lazy(() => import('src/views/grades/CreateView'))
  },
  //#endregion

  //#region -- INSTITUTES
  {
    exact: true,
    path: [
      '/app/institutes/label/:customLabel',
      '/app/institutes/:systemLabel',
      '/app/institutes'
    ],
    component: lazy(() => import('src/views/institutes'))
  },
  {
    exact: true,
    path: '/app/institute/edit/:entityId',
    component: lazy(() => import('src/views/institutes/EditView'))
  },
  {
    exact: true,
    path: '/app/institute/edit-address/:entityId',
    component: lazy(() => import('src/views/institutes/EditView/Address'))
  },
  {
    exact: true,
    path: '/app/institute/edit-description/:entityId',
    component: lazy(() => import('src/views/institutes/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/institute/view/:entityId',
    component: lazy(() => import('src/views/institutes/DetailsView'))
  },
  {
    exact: true,
    path: '/app/institute/create',
    component: lazy(() => import('src/views/institutes/CreateView'))
  },
  //#endregion

  //#endregion

  //#region RESOURCE

  //#region -- PERSONS
  {
    exact: true,
    path: '/app/person/calendar',
    component: lazy(() => import('src/views/calendar/PersonsCourseCalendar'))
  },
  {
    exact: true,
    path: [
      '/app/persons/label/:customLabel',
      '/app/persons/:systemLabel',
      '/app/persons'
    ],
    component: lazy(() => import('src/views/resources/persons'))
  },
  {
    exact: true,
    path: '/app/person/edit/:entityId',
    component: lazy(() => import('src/views/resources/persons/EditView'))
  },
  {
    exact: true,
    path: '/app/person/edit-address/:entityId',
    component: lazy(() =>
      import('src/views/resources/persons/EditView/Address')
    )
  },
  {
    exact: true,
    path: '/app/person/edit-additional/:entityId',
    component: lazy(() =>
      import('src/views/resources/persons/EditView/Additional')
    )
  },
  {
    exact: true,
    path: '/app/person/edit-bio/:entityId',
    component: lazy(() => import('src/views/resources/persons/EditView/Bio'))
  },
  {
    exact: true,
    path: '/app/person/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/resources/persons/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/person/view/:entityId',
    component: lazy(() => import('src/views/resources/persons/DetailsView'))
  },
  {
    exact: true,
    path: '/app/person/create',
    component: lazy(() => import('src/views/resources/persons/CreateView'))
  },
  //#endregion

  //#region -- LOCATIONS
  {
    exact: true,
    path: '/app/location/calendar',
    component: lazy(() => import('src/views/calendar/LocationsCourseCalendar'))
  },
  {
    exact: true,
    path: [
      '/app/locations/label/:customLabel',
      '/app/locations/:systemLabel',
      '/app/locations'
    ],
    component: lazy(() => import('src/views/resources/locations'))
  },
  {
    exact: true,
    path: '/app/location/edit/:entityId',
    component: lazy(() => import('src/views/resources/locations/EditView'))
  },
  {
    exact: true,
    path: '/app/location/edit-address/:entityId',
    component: lazy(() =>
      import('src/views/resources/locations/EditView/Address')
    )
  },
  {
    exact: true,
    path: '/app/location/edit-additional/:entityId',
    component: lazy(() =>
      import('src/views/resources/locations/EditView/Additional')
    )
  },
  {
    exact: true,
    path: '/app/location/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/resources/locations/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/location/view/:entityId',
    component: lazy(() => import('src/views/resources/locations/DetailsView'))
  },
  {
    exact: true,
    path: '/app/location/create',
    component: lazy(() => import('src/views/resources/locations/CreateView'))
  },
  //#endregion

  //#region -- DEVICES
  {
    exact: true,
    path: '/app/device/calendar',
    component: lazy(() => import('src/views/calendar/DevicesCourseCalendar'))
  },
  {
    exact: true,
    path: [
      '/app/devices/label/:customLabel',
      '/app/devices/:systemLabel',
      '/app/devices'
    ],
    component: lazy(() => import('src/views/resources/devices'))
  },
  {
    exact: true,
    path: '/app/device/edit/:entityId',
    component: lazy(() => import('src/views/resources/devices/EditView'))
  },
  {
    exact: true,
    path: '/app/device/edit-address/:entityId',
    component: lazy(() =>
      import('src/views/resources/devices/EditView/Address')
    )
  },
  {
    exact: true,
    path: '/app/device/edit-additional/:entityId',
    component: lazy(() =>
      import('src/views/resources/devices/EditView/Additional')
    )
  },
  {
    exact: true,
    path: '/app/device/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/resources/devices/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/device/view/:entityId',
    component: lazy(() => import('src/views/resources/devices/DetailsView'))
  },
  {
    exact: true,
    path: '/app/device/create',
    component: lazy(() => import('src/views/resources/devices/CreateView'))
  },
  //#endregion

  //#region -- RESOURCE-GROUPS
  {
    exact: true,
    path: [
      '/app/resourcegroups/label/:customLabel',
      '/app/resourcegroups/:systemLabel',
      '/app/resourcegroups'
    ],
    component: lazy(() => import('src/views/resourcegroups'))
  },
  {
    exact: true,
    path: '/app/resourcegroup/edit/:entityId',
    component: lazy(() => import('src/views/resourcegroups/EditView'))
  },
  {
    exact: true,
    path: '/app/resourcegroup/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/resourcegroups/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/resourcegroup/view/:entityId',
    component: lazy(() => import('src/views/resourcegroups/DetailsView'))
  },
  {
    exact: true,
    path: '/app/resourcegroup/create',
    component: lazy(() => import('src/views/resourcegroups/CreateView'))
  },
  //#endregion

  //#region -- RESOURCE-CATS
  {
    exact: true,
    path: [
      '/app/resourcecats/label/:customLabel',
      '/app/resourcecats/:systemLabel',
      '/app/resourcecats'
    ],
    component: lazy(() => import('src/views/resourcecats'))
  },
  {
    exact: true,
    path: '/app/resourcecat/edit/:entityId',
    component: lazy(() => import('src/views/resourcecats/EditView'))
  },
  {
    exact: true,
    path: '/app/resourcecat/edit-description/:entityId',
    component: lazy(() => import('src/views/resourcecats/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/resourcecat/view/:entityId',
    component: lazy(() => import('src/views/resourcecats/DetailsView'))
  },
  {
    exact: true,
    path: '/app/resourcecat/create',
    component: lazy(() => import('src/views/resourcecats/CreateView'))
  }
  //#endregion

  //#endregion
];

export default RoutesConfig;
