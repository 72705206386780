import axios from 'src/utils/axios';
import AuthService from 'src/services/authService';
import MeService from 'src/services/meService';
import * as Actions from 'src/actions/types';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const user = await AuthService.loginWithEmailAndPassword(email, password);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user }
      });

      // My Status
      dispatch({ type: Actions.ME_STATUS_REQUEST });
      const mystatus = await MeService.getMyStatus();
      dispatch({
        type: Actions.ME_STATUS_RECEIVE,
        payload: { entity: mystatus }
      });

      // My Tenant
      dispatch({ type: Actions.ME_TENANT_REQUEST });
      const mytenant = await MeService.getMyTenant();
      dispatch({
        type: Actions.ME_TENANT_RECEIVE,
        payload: { entity: mytenant }
      });

      // My Tenants
      dispatch({ type: Actions.ME_TENANT_LIST_REQUEST });
      const mytenantsResult = await MeService.getMyTenants();
      const mytenants = mytenantsResult.edges;
      const mytenantsPageInfo = mytenantsResult.pageInfo;
      dispatch({
        type: Actions.ME_TENANT_LIST_RECEIVE,
        payload: { entities: mytenants, pageinfo: mytenantsPageInfo }
      });

      // My Member
      dispatch({ type: Actions.ME_MEMBER_REQUEST });
      const mymember = await MeService.getMyMember();
      dispatch({
        type: Actions.ME_MEMBER_RECEIVE,
        payload: { entity: mymember }
      });

      // My Organization
      dispatch({ type: Actions.ME_ORG_REQUEST });
      const myorganization = await MeService.getMyOrganization();
      dispatch({
        type: Actions.ME_ORG_RECEIVE,
        payload: { entity: myorganization }
      });

      // My Organizations
      dispatch({ type: Actions.ME_ORG_LIST_REQUEST });
      const myorganizationsResult = await MeService.getMyOrganizations();
      const myorganizations = myorganizationsResult.edges;
      const myorganizationsPageInfo = myorganizationsResult.pageInfo;
      dispatch({
        type: Actions.ME_ORG_LIST_RECEIVE,
        payload: {
          entities: myorganizations,
          pageinfo: myorganizationsPageInfo
        }
      });

      // My Delegate
      dispatch({ type: Actions.ME_DELEGATE_REQUEST });
      const mydelegate = await MeService.getMyDelegate();
      dispatch({
        type: Actions.ME_DELEGATE_RECEIVE,
        payload: { entity: mydelegate }
      });

      // My Delegates
      dispatch({ type: Actions.ME_DELEGATE_LIST_REQUEST });
      const mydelegatesResult = await MeService.getMyDelegates();
      const mydelegates = mydelegatesResult.edges;
      const mydelegatesPageInfo = mydelegatesResult.pageInfo;
      dispatch({
        type: Actions.ME_DELEGATE_LIST_RECEIVE,
        payload: { entities: mydelegates, pageinfo: mydelegatesPageInfo }
      });

      // My Contact
      dispatch({ type: Actions.ME_CONTACT_REQUEST });
      const mycontact = await MeService.getMyContact();
      dispatch({
        type: Actions.ME_CONTACT_RECEIVE,
        payload: { entity: mycontact }
      });

      // My Site
      dispatch({ type: Actions.ME_SITE_REQUEST });
      try {
        const mysite = await MeService.getMySite();
        dispatch({
          type: Actions.ME_SITE_RECEIVE,
          payload: { entity: mysite }
        });
      } catch {
        dispatch({
          type: Actions.ME_SITE_RECEIVE,
          payload: { entity: null }
        });
      }

      // My DelegateSite
      dispatch({ type: Actions.ME_DELEGATESITE_REQUEST });
      try {
        const mydelegatesite = await MeService.getMyDelegateSite();
        dispatch({
          type: Actions.ME_DELEGATESITE_RECEIVE,
          payload: { entity: mydelegatesite }
        });
      } catch {
        dispatch({
          type: Actions.ME_DELEGATESITE_RECEIVE,
          payload: { entity: null }
        });
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      dispatch({ type: Actions.ME_STATUS_FINISHED });
      dispatch({ type: Actions.ME_TENANT_FINISHED });
      dispatch({ type: Actions.ME_MEMBER_FINISHED });
      dispatch({ type: Actions.ME_ORG_FINISHED });
      dispatch({ type: Actions.ME_DELEGATE_FINISHED });
      dispatch({ type: Actions.ME_CONTACT_FINISHED });
      dispatch({ type: Actions.ME_SITE_FINISHED });
      dispatch({ type: Actions.ME_DELEGATESITE_FINISHED });
      throw error;
    }
  };
}

export function silentLogin() {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const user = await AuthService.loginInWithToken();
      dispatch({
        type: SILENT_LOGIN,
        payload: { user }
      });

      // My Status
      dispatch({ type: Actions.ME_STATUS_REQUEST });
      const mystatus = await MeService.getMyStatus();
      dispatch({
        type: Actions.ME_STATUS_RECEIVE,
        payload: { entity: mystatus }
      });

      // My Tenant
      dispatch({ type: Actions.ME_TENANT_REQUEST });
      const mytenant = await MeService.getMyTenant();
      dispatch({
        type: Actions.ME_TENANT_RECEIVE,
        payload: { entity: mytenant }
      });

      // My Tenants
      dispatch({ type: Actions.ME_TENANT_LIST_REQUEST });
      const mytenantsResult = await MeService.getMyTenants();
      const mytenants = mytenantsResult.edges;
      const mytenantsPageInfo = mytenantsResult.pageInfo;
      dispatch({
        type: Actions.ME_TENANT_LIST_RECEIVE,
        payload: { entities: mytenants, pageinfo: mytenantsPageInfo }
      });

      // My Member
      dispatch({ type: Actions.ME_MEMBER_REQUEST });
      const mymember = await MeService.getMyMember();
      dispatch({
        type: Actions.ME_MEMBER_RECEIVE,
        payload: { entity: mymember }
      });

      // My Organization
      dispatch({ type: Actions.ME_ORG_REQUEST });
      const myorganization = await MeService.getMyOrganization();
      dispatch({
        type: Actions.ME_ORG_RECEIVE,
        payload: { entity: myorganization }
      });

      // My Organizations
      dispatch({ type: Actions.ME_ORG_LIST_REQUEST });
      const myorganizationsResult = await MeService.getMyOrganizations();
      const myorganizations = myorganizationsResult.edges;
      const myorganizationsPageInfo = myorganizationsResult.pageInfo;
      dispatch({
        type: Actions.ME_ORG_LIST_RECEIVE,
        payload: {
          entities: myorganizations,
          pageinfo: myorganizationsPageInfo
        }
      });

      // My Delegate
      dispatch({ type: Actions.ME_DELEGATE_REQUEST });
      const mydelegate = await MeService.getMyDelegate();
      dispatch({
        type: Actions.ME_DELEGATE_RECEIVE,
        payload: { entity: mydelegate }
      });

      // My Delegates
      dispatch({ type: Actions.ME_DELEGATE_LIST_REQUEST });
      const mydelegatesResult = await MeService.getMyDelegates();
      const mydelegates = mydelegatesResult.edges;
      const mydelegatesPageInfo = mydelegatesResult.pageInfo;
      dispatch({
        type: Actions.ME_DELEGATE_LIST_RECEIVE,
        payload: { entities: mydelegates, pageinfo: mydelegatesPageInfo }
      });

      // My Contact
      dispatch({ type: Actions.ME_CONTACT_REQUEST });
      const mycontact = await MeService.getMyContact();
      dispatch({
        type: Actions.ME_CONTACT_RECEIVE,
        payload: { entity: mycontact }
      });

      // My Site
      dispatch({ type: Actions.ME_SITE_REQUEST });
      const mysite = await MeService.getMySite();
      dispatch({
        type: Actions.ME_SITE_RECEIVE,
        payload: { entity: mysite }
      });

      // My DelegateSite
      dispatch({ type: Actions.ME_DELEGATESITE_REQUEST });
      const mydelegatesite = await MeService.getMyDelegateSite();
      dispatch({
        type: Actions.ME_DELEGATESITE_RECEIVE,
        payload: { entity: mydelegatesite }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      dispatch({ type: Actions.ME_STATUS_FINISHED });
      dispatch({ type: Actions.ME_TENANT_FINISHED });
      dispatch({ type: Actions.ME_MEMBER_FINISHED });
      dispatch({ type: Actions.ME_ORG_FINISHED });
      dispatch({ type: Actions.ME_DELEGATE_FINISHED });
      dispatch({ type: Actions.ME_CONTACT_FINISHED });
      dispatch({ type: Actions.ME_SITE_FINISHED });
      dispatch({ type: Actions.ME_DELEGATESITE_FINISHED });
      throw error;
    }
  };
}

export function logout() {
  return async dispatch => {
    AuthService.logout();
    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

// Legacy from Starter - NOT used in App

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data
      })
    );
  };
}
